define("discourse/plugins/discourse-custom-wizard/discourse/helpers/wizard-char-counter", ["exports", "@ember/template", "discourse-i18n"], function (_exports, _template, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = wizardCharCounter;
  function wizardCharCounter(body, maxLength) {
    let bodyLength = body ? body.length : 0;
    let finalString;
    if (maxLength) {
      let isOverMax = bodyLength > maxLength ? "true" : "false";
      finalString = `<div class="body-length" data-length=${bodyLength} data-over-max=${isOverMax}>${bodyLength} / ${(0, _discourseI18n.i18n)("wizard.x_characters", {
        count: parseInt(maxLength, 10)
      })}</div>`;
    } else {
      finalString = `<div class="body-length">${(0, _discourseI18n.i18n)("wizard.x_characters", {
        count: parseInt(bodyLength, 10)
      })}</div>`;
    }
    return (0, _template.htmlSafe)(finalString);
  }
});