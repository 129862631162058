define("discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-category-settings", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax-error", "discourse/plugins/discourse-custom-wizard/discourse/models/custom-wizard-admin", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _ajaxError, _customWizardAdmin, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <h3>{{i18n "admin.wizard.category_settings.custom_wizard.title"}}</h3>
  <section class="field new-topic-wizard">
    <label for="new-topic-wizard">
      {{i18n "admin.wizard.category_settings.custom_wizard.create_topic_wizard"}}
    </label>
    <div class="controls">
      <ComboBox
        @value={{this.wizardListVal}}
        @content={{this.wizardList}}
        @onChange={{this.changeWizard}}
        @options={{hash none="admin.wizard.select"}}
      />
    </div>
  </section>
  */
  {
    "id": "+dOmntMz",
    "block": "[[[10,\"h3\"],[12],[1,[28,[35,1],[\"admin.wizard.category_settings.custom_wizard.title\"],null]],[13],[1,\"\\n\"],[10,\"section\"],[14,0,\"field new-topic-wizard\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,\"for\",\"new-topic-wizard\"],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"admin.wizard.category_settings.custom_wizard.create_topic_wizard\"],null]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[8,[39,5],null,[[\"@value\",\"@content\",\"@onChange\",\"@options\"],[[30,0,[\"wizardListVal\"]],[30,0,[\"wizardList\"]],[30,0,[\"changeWizard\"]],[28,[37,6],null,[[\"none\"],[\"admin.wizard.select\"]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"h3\",\"i18n\",\"section\",\"label\",\"div\",\"combo-box\",\"hash\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/components/custom-wizard-category-settings.hbs",
    "isStrictMode": false
  });
  class CustomWizardCategorySettings extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "wizardList", [_tracking.tracked], function () {
      return [];
    }))();
    #wizardList = (() => (dt7948.i(this, "wizardList"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "wizardListVal", [_tracking.tracked], function () {
      return this.args?.category?.custom_fields?.create_topic_wizard;
    }))();
    #wizardListVal = (() => (dt7948.i(this, "wizardListVal"), void 0))();
    constructor() {
      super(...arguments);
      _customWizardAdmin.default.all().then(result => {
        this.wizardList = result;
      }).catch(_ajaxError.popupAjaxError);
    }
    changeWizard(wizard) {
      this.wizardListVal = wizard;
      this.args.category.custom_fields.create_topic_wizard = wizard;
    }
    static #_3 = (() => dt7948.n(this.prototype, "changeWizard", [_object.action]))();
  }
  _exports.default = CustomWizardCategorySettings;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CustomWizardCategorySettings);
});