define("discourse/plugins/discourse-custom-wizard/discourse/templates/connectors/category-custom-settings/custom-wizard-category-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <CustomWizardCategorySettings @category={{this.category}} />
  */
  {
    "id": "WSQkUCsq",
    "block": "[[[8,[39,0],null,[[\"@category\"],[[30,0,[\"category\"]]]],null]],[],false,[\"custom-wizard-category-settings\"]]",
    "moduleName": "discourse/plugins/discourse-custom-wizard/discourse/templates/connectors/category-custom-settings/custom-wizard-category-settings.hbs",
    "isStrictMode": false
  });
});